import React, { HTMLAttributes } from 'react'
import { getString } from '..'

type TSProps = {
  stringKey: string
  as?: string
  raw?: boolean
  style?: React.CSSProperties
  className?: string
}

const S = ({ stringKey, as = 'div', raw = false, ...rest }: TSProps) => {
  const Element = as
  const string = getString(stringKey)
  let props: HTMLAttributes<{}> = { ...rest }
  raw ? (props.children = string) : (props.dangerouslySetInnerHTML = { __html: string })
  return <Element {...props} />
}

export { S }
