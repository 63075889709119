import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { GrandstandLogo } from '@grandstand-web/bally-web-shared/src/components/GrandstandLogo'
import { ApiClient } from '@grandstand-web/bally-web-shared/src/newPackages/ApiClient'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import FooterDropdown from './FooterDropdown'

export interface TeamData {
  team_id: string
  name: string
  market: string
  partner: boolean
  league: string
  light_image: string
  dark_image: string
  icon: string
  dtc: boolean
  alias: string
  id: string
  picker_field: string
}

const TrustArcWrapper = styled.div``

const FooterDiv = styled.div`
  width: 100%;
  overflow-x: hidden;
  max-width: 1728px;
  background-color: var(--canvas);
  margin: 16px auto;
  padding: 48px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    padding: 16px;
  }
`

const ImageContainer = styled.div`
  margin-left: 48px;
  position: relative;
  overflow: visible;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column: 1;
  padding-right: 15%;
  max-width: 735px;
  max-height: 190px;
  @media screen and (max-width: 768px) {
    max-width: 720px;
    max-height: 186px;
    padding-right: 24px;
    margin-left: 0;
    grid-column: 1;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 425px) {
    max-width: 327px;
    max-height: 85px;
    padding-right: 0;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  grid-column-start: 2;
  grid-column-end: -1;
  grid-row: 1;
  gap: 40px;
  @media screen and (max-width: 768px) {
    grid-column: 1;
    grid-row: 2;
  }
  @media screen and (max-width: 425px) {
    grid-column: 1;
    grid-row: 2;
  }
`

const SocialIconsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 216px;
  height: 24px;
  grid-row-start: 1;
  grid-column: 2;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    grid-row-start: 2;
    grid-column: 1;
  }
  @media screen and (max-width: 425px) {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-top: 0;
  }
`

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`

const FirstLinksDiv = styled.div`
  line-height: 2.5;
  color: var(--on-background);
  @media screen and (max-width: 768px) {
    grid-row: 2;
    grid-column: 1;
  }
  @media screen and (max-width: 425px) {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`
const SecondLinksDiv = styled.div`
  line-height: 2.5;
  color: var(--on-background);
  margin-right: 48px;
  @media screen and (max-width: 768px) {
    grid-row: 2;
    grid-column: 2;
  }
  @media screen and (max-width: 425px) {
    margin: 0;
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

const Line = styled.hr`
  background-color: var(--divider-canvas);
  height: 1px;
  border: none;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  @media screen and (max-width: 768px) {
    grid-column-end: 3;
    grid-row: 3;
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (max-width: 425px) {
    grid-row: 5;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const CopyrightDiv = styled.div`
  color: var(--on-background-alt);
  margin-left: 48px;
  grid-row: 4;
  grid-column: 1;
  padding-right: 24px;
  @media screen and (max-width: 768px) {
    grid-row: 5;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-left: 0;
    margin-top: 24px;
  }
  @media screen and (max-width: 425px) {
    grid-row: 8;
  }
`
const PrivacyPolicyDiv = styled.div`
  grid-row: 4;
  grid-column: 2;
  @media screen and (max-width: 768px) {
    grid-column: 1;
  }
  @media screen and (max-width: 425px) {
    grid-row: 6;
  }
`
const TermsOfServiceDiv = styled.div`
  grid-row: 4;
  grid-column: 3;
  margin-right: 48px;
  @media screen and (max-width: 768px) {
    grid-column: 1;
    margin-right: 0px;
    justify-self: end;
  }
  @media screen and (max-width: 425px) {
    grid-row: 6;
    grid-column: 1;
    margin-right: 0;
    justify-self: end;
  }
`

const Footer = () => {
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)

  const [teamsData, setTeamsData] = useState<TeamData[]>()

  useEffect(() => {
    const getTeamsData = async () => {
      const data = await ApiClient.convenientApiFetch({
        url: currentConfig.API.services.team_services.teams,
        method: 'GET',
      })
      setTeamsData(await data.json())
    }
    getTeamsData()
  }, [currentConfig])

  return (
    <FooterDiv>
      <ImageContainer>
        <Link href="/">
          <GrandstandLogo width="100%" logoStyle="horiz" />
        </Link>
      </ImageContainer>
      <Column>
        <SocialIconsDiv>
          <Link href="https://instagram.com/ballysports?igshid=MzRlODBiNWFlZA==">
            <GrandstandIcon icon="social_instagram" />
          </Link>
          <Link href="https://www.youtube.com/@ballysports">
            <GrandstandIcon icon="social_youtube" />
          </Link>
          <Link href="https://x.com/ballysports?s=11&t=3JYYEoo11x2GkYo3e2jb3A">
            <GrandstandIcon icon="social_twitter" />
          </Link>
          <Link href="https://www.facebook.com/BallySports?mibextid=LQQJ4d">
            <GrandstandIcon icon="social_facebook" />
          </Link>
        </SocialIconsDiv>
        <FooterDropdown title="FanDuel Sports Network Team Partners" teams={teamsData as TeamData[]}></FooterDropdown>
        <LinksContainer>
          <FirstLinksDiv>
            <Link href={getString('subscriber_agreement.url')} target="_blank">
              <p>Subscriber Agreement</p>
            </Link>
            <Link href="https://help.fanduelsportsnetwork.com/category/detail/Press" target="_blank">
              <p>Press</p>
            </Link>
            <Link href={getString('more.account.help.url')} target="_blank">
              <p>Help</p>
            </Link>
          </FirstLinksDiv>
          <SecondLinksDiv>
            <a href="javascript: utag.gdpr.showDoNotSellPrompt('EN')">
              <p>Do Not Sell My Information</p>
            </a>
            <a href="#" onClick={() => window.utag && window.utag.gdpr.showConsentPreferences()}>
              Cookie Preferences
            </a>
            <Link href={getString('footer.careers.url')} target="_blank">
              <p>{getString('footer.careers.label')}</p>
            </Link>
          </SecondLinksDiv>
        </LinksContainer>
      </Column>
      <Line />
      <CopyrightDiv>
        <p>&copy; 2024 Diamond Sports, LLC. All Rights Reserved.</p>
      </CopyrightDiv>
      <PrivacyPolicyDiv>
        <Link href={getString('tos.privacyPolicy.url')} target="_blank">
          <p>Privacy Policy</p>
        </Link>
      </PrivacyPolicyDiv>
      <TermsOfServiceDiv>
        <Link href={getString('tos.url')} target="_blank">
          <p>Terms of Service</p>
        </Link>
      </TermsOfServiceDiv>
    </FooterDiv>
  )
}

export default Footer
