import { GrandstandButton } from '@/components/Buttons'
import { useTopNavStore } from '@/components/TopNav/TopNavContext'
import { NavBarContainer, StickyNavWrapper } from '@/components/TopNav/styles'
import GrandstandZipcodeInput from '@/components/ZipcodeInput'
import { breakpoints } from '@/styles/breakpoints'
import { usePurchasingDisabled } from '@grandstand-web/bally-web-shared/src/hooks/usePurchasingDisabled'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import { isValidZipcode } from '@grandstand-web/bally-web-shared/src/utils/stringUtils'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled from 'styled-components'

export const NavBottomPortalWrapper = styled(StickyNavWrapper)<{ topHeight: number; startItemHeight: number }>`
  top: ${({ topHeight, startItemHeight }) => topHeight + startItemHeight - 2}px;
  z-index: 3001;
  background-color: var(--canvas);
  ${breakpoints.up('desktop')} {
    top: ${({ topHeight }) => topHeight - 2}px;
  }
`

const Container = styled(NavBarContainer)`
  padding: 0;
  ${breakpoints.up('tablet')} {
    padding-bottom: 0;
  }
  ${breakpoints.up('desktop')} {
    padding-bottom: 0;
  }
`

const FormSection = styled.div`
  background-color: var(--canvas);
  padding: 0 0 var(--mobile-container-spacer);
  ${breakpoints.up('tablet')} {
  }
  ${breakpoints.up('desktop')} {
    padding: 0 var(--container-spacer);
  }
`
const FormBar = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid var(--divider-canvas);
  padding: var(--mobile-container-spacer);

  ${breakpoints.up('tablet')} {
    flex-direction: row;
    flex-wrap: wrap;

    padding: var(--mobile-container-spacer);
  }
  ${breakpoints.up('desktop')} {
    padding: var(--tablet-container-spacer) 0;
    justify-content: space-between;
  }
`

const FormCTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 16px 0 0;
  ${breakpoints.up('md')} {
    width: 50%;
    padding: 0 0 0 16px;
  }
  ${breakpoints.up('desktop')} {
    width: auto;
    margin: 0 0 0 auto;
  }
`
const FormButton = styled(GrandstandButton)`
  width: 100%;
  ${breakpoints.up('md')} {
    width: auto;
    padding-left: var(--mobile-container-spacer);
    &,
    * {
      white-space: nowrap;
    }
  }
`

const SignupText = styled.p`
  font-weight: 700;
  text-transform: uppercase;
`
const ZipCodeDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  ${breakpoints.up('md')} {
    width: 50%;
  }
  ${breakpoints.up('desktop')} {
    flex: 1 1 auto;
    max-width: 66.667%;

    flex-wrap: nowrap;
  }
`
const ZipCodeInput = styled.div`
  width: 100%;
  ${breakpoints.up('desktop')} {
    flex: 1 1 auto;
    max-width: 50%;
  }
`
const ZipInputLabel = styled.label`
  width: 100%;

  padding: 8px 0 16px;
  ${breakpoints.up('desktop')} {
    width: auto;
    max-width: 320px;
    padding-left: 24px;
  }
`

export const NavBottom = () => {
  const { zipcode, setZipcode, isLoggedIn } = useTopNavStore()
  const analyticService = useContext(AnalyticServiceContext)
  const router = useRouter()
  const { isPurchasingDisabled } = usePurchasingDisabled()

  const handleGetBallySportsPlusClick = () => {
    analyticService.clickGetBallySportsPlus(
      getString('onboarding.authentication.getBallyPlus.cta') ?? 'Get FanDuel Sports Network'
    )
    router.push('/packages')
  }
  const handleZipcodeChange = (next: string) => {
    const valid = isValidZipcode(next)
    if (valid) {
      setZipcode(next)
    }
  }
  if (isLoggedIn) {
    return null
  }

  return (
    <Container>
      <FormSection>
        <FormBar>
          <ZipCodeDiv>
            <ZipCodeInput>
              <GrandstandZipcodeInput onChange={handleZipcodeChange} placeholder="Zipcode" value={zipcode} />
            </ZipCodeInput>
            <ZipInputLabel>
              <b>Your Home Zip Code </b>determines what teams and games are available to you.
            </ZipInputLabel>
          </ZipCodeDiv>
          {!isPurchasingDisabled && (
            <FormCTAWrapper>
              <FormButton buttonSize="sm" buttonStyle="primary" onClick={handleGetBallySportsPlusClick}>
                <SignupText>
                  {getString('onboarding.authentication.getBallyPlus.cta') ?? 'Get FanDuel Sports Network'}
                </SignupText>
              </FormButton>
            </FormCTAWrapper>
          )}
        </FormBar>
      </FormSection>
    </Container>
  )
}
